import React from "react"
import SEO from "../components/seo"
import HeroContainer, { HeroContent } from "../components/containers/hero"

const InvitePage = () => {
  return (
    <>
      <SEO title="Invitation" />
      <HeroContainer variant="light">
        <HeroContent>
          <h2>Coming Soon...</h2>
        </HeroContent>
      </HeroContainer>
    </>
  )
}

export default InvitePage
